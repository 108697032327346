.checkbox-solid-primary label:before {
  background-color: inherit;
}

.checkbox-solid-primary {
  // height: 38px;
  align-items: center;
  display: flex;

	.custom-checkbox {
		margin-top: auto;
		margin-bottom: auto;
	}
}

.checkbox-solid-primary input[type='checkbox']:checked + label::before {
	background-color: #d32f34;
	border-color: #d32f34;
	color: #fff;
}

.checkbox-solid-primary label:before {
	background-color: white;
	border-color: #AFAFAF;
	color: #fff;
}

.custom-checkbox {
	display: inline-flex !important;
	align-items: center;
	padding-left: 0px !important;

  span{
    margin-left: 8px;
  }
}
