

.arrow {
	height: 39px;
	display: flex;
	align-items: center;
  flex-grow: 1;

	.horizontalDashedLine {
		border-bottom: 2px dashed;
		color: #d32f34;
		flex-grow: 1;
    min-width: 24px;
		height: 2px;
	}
	.chevron {
		color: #d32f34;
		&.right {
			margin: 8px -6px;
		}
	}
}

.arrowVertical {
	display: flex;
	flex-direction: column;
	align-items: center;
  
	.verticalDashedLine {
		border-left: 2px dashed;
		color: #c8a800;
		width: 2px;
		height: 24px;
	}
	.chevron {
		color: #c8a800;
		&.down {
			margin-top: -6px;
			margin-bottom: -6px;
      &.noLine{
        margin-top: -4px;
        margin-bottom: -4px;
      }
		}
	}
}
