@import 'assets/scss/utils/_variables.scss';
@import 'assets/scss/utils/_utilities.scss';

.sms-page {
	.title {
		width: 100%;
		padding: 16px 24px;
		color: #d32f34;
		/* Primario/Green.5 */

		background: #fbeaeb;
		/* Primario/Strokes */

		border: 1px solid #ecf3fa;
		border-radius: 4px;
		display: flex;
		align-items: center;

		& > * {
			margin: 0 !important;
		}
	}
}
