@import 'assets/scss/utils/_variables.scss';
@import 'assets/scss/utils/_utilities.scss';

.create-pyramid-page {
	padding: 24px 0;

	.title {
		color: $primary-color-brand;
		padding: 16px 24px;
		background-color: #fbeaeb;
		border: 1px solid #ecf3fa;
		border-radius: 4px;
	}

	.containerForm {
		background-color: white;
		padding: 24px;
		box-shadow: 0px 0px 21px rgba(89, 102, 122, 0.1);
		border-radius: 8px;
	}

	.selectedOptionsTitle {
		background-color: #f8f8f8;
		border-bottom: 1px solid #ced4da;
		padding: 8px 16px;

		> * {
			color: #d32f34;
			font-size: 16px;
		}
	}

	.selectedOptionsContainer {
		padding: 15px 24px;
    border-bottom: 1px solid #ECF3FA;
    
		.labelContainer {
			display: flex;

			justify-content: space-between;

			.optionTitle {
				font-size: 14px;
				color: #d32f34;
			}
			.optionDetail {
				color: #afafaf;
				font-size: 12px;
			}
		}

		ul {
			display: flex;
			align-items: center;
			gap: 5px;
		}

		.list-horizontal li {
			padding: 4px 8px;
			background: #f8f8f8;
			display: inline-flex;
			align-items: center;
			border: 1px solid #ecf3fa;
			border-radius: 4px;

      > :last-child{
        margin-left: 10px;
        color: $primary-color;
        cursor: pointer;
      }
		}
		.list-horizontal li:before {
			content: none;
			color: #ced4da;
			font-size: 20px;
		}

		.list-horizontal li:first-child:before {
			content: none;
		}
	}
}
