.wrapper-pyramid {
	display: flex;
	flex-direction: column;
	// --triangle-shape: url('./layer1.svg');
	// --triangle-shape: url('data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMTAwIDEwMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNNTAsMCBMMTAwLDEwMCBMMCwxMDAgeiIvPjwvc3ZnPg==');
	// -webkit-mask-image: var(--triangle-shape);
	// -webkit-mask-repeat: no-repeat;
	// -webkit-mask-position: center;
	// mask-image: var(--triangle-shape);
	// mask-repeat: no-repeat;
	// mask-position: center;

	.layer {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 74px;
		// color: #fff;
		// font-size: 15px;
		// border: 0.2em solid #fff;
		margin: 2px;
		--triangle-shape: url('./layer6.svg');
		-webkit-mask-image: var(--triangle-shape);
		-webkit-mask-repeat: no-repeat;
		-webkit-mask-position: center;
		mask-image: var(--triangle-shape);
		mask-repeat: no-repeat;
		mask-position: center;
		margin-left: -35px;

		&.first {
			--triangle-shape: url('./layer1.svg');
		}
		&.second {
			--triangle-shape: url('./layer2.svg');
		}
		&.three {
			--triangle-shape: url('./layer3.svg');
		}
		&.four {
			--triangle-shape: url('./layer4.svg');
		}
		&.five {
			--triangle-shape: url('./layer5.svg');
		}
		&.six {
			--triangle-shape: url('./layer6.svg');
		}
	}
	.layer-item {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		.layer-info {
			padding: 5px 10px;
			height: 74px;

			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: flex-start;

			background: linear-gradient(
				266.46deg,
				#d2dce0 -30.84%,
				#d2dce0 15.8%,
				#e6ebed 42.62%,
				#f4f6f7 65.94%,
				#fcfdfd 84.6%,
				#ffffff 85.76%
			);

			padding-right: 60px;

			.layer-icon {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: center;
				min-height: 64px !important;
				min-width: 64px !important;
				border-radius: 100%;
				background: linear-gradient(
					180deg,
					#d2dce0 -30.84%,
					#d2dce0 15.8%,
					#e6ebed 42.62%,
					#f4f6f7 65.94%,
					#fcfdfd 84.6%,
					#ffffff 85.76%
				);
				padding: 8px;
			}

			.managers-info {
				display: flex;
				flex-direction: row;
				align-items: center;

				span {
					font-weight: 500;
					font-size: 12px;
					line-height: 14px;

					&.percentage-badge {
						display: flex;
						flex-direction: row;
						justify-content: center;
						align-items: center;
						padding: 2px 6px;
						margin-left: 4px;

						width: 37px;
						height: 16px;

						background: #fbeaeb;

						border: 1px solid #d32f34;
						border-radius: 24px;
						color: #d32f34;
					}
				}
			}
		}
	}
}

span.level-name {
	text-overflow: ellipsis !important;
	white-space: nowrap !important;
	display: inline-block !important;
	width: 180px !important;
	overflow: hidden !important;
}

.pyramid_wrap {
	margin-top: 200px;
	height: 100%;
	text-align: center;

	.pyramidLayer {
		position: relative;
		margin: 6px auto;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.pyramidLayer:before,
	.pyramidLayer:after {
		position: absolute;
		top: 0;
		display: block;
		width: 30px;
		height: 100%;
		content: '';
	}

	.pyramidLayer:before {
		left: -15px;
		transform: skew(-25deg);
	}

	.pyramidLayer:after {
		right: -15px;
		transform: skew(25deg);
	}

	.pyramidLayer:before,
	.pyramidLayer:after {
		background: inherit;
	}

	.category_one {
		width: 20px;
		height: 40px;
		background: tomato;
	}

	.category_one:before,
	.category_one:after {
		width: 30px;
		height: 40px;
		background: inherit;
	}

	// .category_one:before {
	// 	left: -16px;
	// 	top: 0;
	// }
	// .category_one:after {
	// 	right: -16px;
	// 	top: 0;
	// }

	.category_one .value:after {
		position: absolute;
		z-index: -1;
		top: -55px;
		left: 50%;
		transform: translateX(-50%);
		content: '';
		display: block;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 0 28px 70px 28px;
		border-color: transparent transparent tomato transparent;
	}

	.category_two {
		width: 55px;
		height: 40px;
		background: orange;
	}
	// .category_two:before,
	// .category_two:after {
	// 	width: 40px;
	// }

	// .category_two:before {
	// 	left: -12px;
	// }
	// .category_two:after {
	// 	right: -12px;
	// }

	.category_three {
		width: 105px;
		height: 40px;
		background: cyan;
	}
	// .category_three:before {
	// 	left: -9px;
	// }
	// .category_three:after {
	// 	right: -9px;
	// }

	.category_four {
		width: 150px;
		height: 40px;
		background: teal;
	}

	.value {
		margin: 0;
		position: relative;
		z-index: 3;
		color: #fff;
		font-size: 16px;
		font-family: Arial, sans-serif;
		font-weight: normal;
		line-height: 1em;
	}
}
