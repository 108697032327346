@import '../../brand/index.scss';

.imageEditor {
	display: flex;
	justify-content: center;
}

.controlsBlock {
	margin-top: 2rem;
	display: flex;
	justify-content: center;
	align-items: center;
	column-gap: 2rem;

	.controlItem {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: auto;
		height: auto;
		padding: 1rem;
		border-radius: 10%;
		gap: 1rem;
	}
}

.scaleFieldBlock {
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: center;
	align-items: center;
}

input[type='range'] {
	-webkit-appearance: none; /* Remove default styling */
	appearance: none;
	width: 100%; /* Full width */
	height: 0.3rem; /* Adjust as needed */
	background-color: #d3d3d3; /* Track color */
	border: 1px solid #ccc; /* Track border */
	border-radius: 0.3rem;
}

input[type='range']::-webkit-slider-thumb {
	-webkit-appearance: none; /* Remove default styling */
	width: 12px; /* Thumb width */
	height: 12px; /* Thumb height */
	background-color: #d32f34; /* Thumb color */
	border: 1px solid #d32f34; /* Thumb border */
	border-radius: 50%; /* Rounded thumb */
	box-shadow: 0 0 1px #2196f3; /* Shadow effect */
}

.nav-item {
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	color: $primary-color-brand;

	.nav-link {
		border: 0;
		width: 100%;
		padding: 10px 0;
		border-radius: 10px 10px 0 0;

		&.active {
			background-color: $primary-color-brand-light;
			color: $primary-color-brand;
		}
	}
}
// .selectedTab {

// 	.nav-link.active {
// 		color: $primary-color-brand;
// 		background-color: transparent;
// 		border-color: transparent;
// 	}
// }
