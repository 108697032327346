.contenedor-loader {
	display: flex;
	flex-wrap: wrap;
	align-items: flex-end;
	margin: 0 auto;
	gap: 10px;
}

.contenedor-loader div {
	width: 10px;
	height: 10px;
	border-radius: 50%;
	background-color: #d32f34;
	animation: salto 1s alternate infinite;
}

contenedor-loader div:nth-child(2) {
	animation-delay: 0.2s;
}
.contenedor-loader div:nth-child(3) {
	animation-delay: 0.4s;
}
@keyframes salto {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}
