:root {
	--theme-deafult: #d32f34;
	--theme-deafult-light: mix(white, #d32f34, 90%);
	--theme-secondary: #f0c50d;
	--theme-secondary-light: mix(white, #f0c50d, 90%);
}

$primary-color-brand: #d32f34;
$primary-color-brand-light: mix(white, #d32f34, 90%);
$secondary-color-brand: #f0c50d;
$secondary-color-brand-light: mix(white, #f0c50d, 90%);
